import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: { originalName: {eq: "platform_04_cloud.png"} }) {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image }) => {
      return (<SectionWrapper className={styles.container} id={i18n('_hash:seamless-backend')}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.textContainer}>
              <Typography variant='h2'
                weight='bold'
                className={styles.title}>
                {i18n('Platform powered by the modern cloud engine')}
              </Typography>
              <Typography variant='h3'
                weight='bold'
                className={styles.subTitle}>
                {i18n('Scalable microservice architecture')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('The microservice architecture scales with the needs of the solution. It is possible to select only microservices required for a project and to add a new microservice to the cluster at any time and it will be working seamlessly with the rest.')}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                // objectPosition='right center'
                className={styles.image}
                fluid={image.fluid}
                alt={i18n('Platform powered by the modern cloud engine')}/>
            </Grid>

          </Grid>

      </SectionWrapper>
  )}}/>
);