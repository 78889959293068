import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Feature from './Feature';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        icon1: imageSharp(fluid: { originalName: {eq: "platform_05_icon_01.jpg"} }) {
          fluid(maxWidth: 117, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon2: imageSharp(fluid: { originalName: {eq: "platform_05_icon_02.jpg"} }) {
          fluid(maxWidth: 117, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon3: imageSharp(fluid: { originalName: {eq: "platform_05_icon_03.jpg"} }) {
          fluid(maxWidth: 117, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        icon4: imageSharp(fluid: { originalName: {eq: "platform_05_icon_04.jpg"} }) {
          fluid(maxWidth: 117, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ icon1, icon2, icon3, icon4 }) => {
      return (<SectionWrapper className={styles.container} id={i18n('_hash:ready-services')}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={12} className={styles.textContainer}>
              <Typography variant='h3'
                weight='bold'
                className={styles.subTitle}>
                {i18n('Application Programming Interfaces (APIs) ')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('A fundamental element of the platform’s architecture is the use of APIs to enrich functionality and elevate the user experience. APIs are essential to ensure personalization, data management, data analytics, and integrations.')}
              </Typography>
            </Grid>

          </Grid>
          
          <Grid container alignItems='center' className={styles.cardsContainer}>
            <Grid item xs={12} sm={6} md={6} lg={3} className={styles.listContainer}>
              <Feature image={icon1} text={i18n('Accessibility of data and services at any level')} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className={styles.listContainer}>
              <Feature image={icon2} text={i18n('Standardizing internal integrations')} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className={styles.listContainer}>
              <Feature image={icon3} text={i18n('Integrate to 3rd party interfaces')} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} className={styles.listContainer}>
              <Feature image={icon4} text={i18n('Optimized for performance and scale')} />
            </Grid>
          </Grid>

      </SectionWrapper>
  )}}/>
);