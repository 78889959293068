import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo1: imageSharp(fluid: { originalName: {eq: "platform_connectivity_icon_01-pnp.jpg"} }) {
          fluid(maxHeight: 76, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        logo2: imageSharp(fluid: { originalName: {eq: "platform_connectivity_icon_02-direct.jpg"} }) {
          fluid(maxHeight: 76, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image1: imageSharp(fluid: { originalName: {eq: "platform_connectivity_image_01-pnp.png"} }) {
          fluid(maxWidth: 840, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image2: imageSharp(fluid: { originalName: {eq: "platform_connectivity_image_02-direct.png"} }) {
          fluid(maxWidth: 840, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ logo1, logo2, image1, image2 }) => {
      return (<SectionWrapper className={styles.container} id={i18n('_hash:data-sources')}>
          <Typography variant='h2'
            weight='bold'
            className={styles.titleContainer}>
            {i18n('Vehicle connectivity for every use case')}
          </Typography>
          
          <Grid container alignItems='flex-start' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={4} className={styles.textContainer}>
              <Img imgStyle={{ objectFit: 'none'}}
                objectFit='none'
                objectPosition='left center'
                className={styles.logo}
                fluid={logo1.fluid}
                alt={i18n('Plug & play vehicle connectivity')}/>
              <Typography variant='h3'
                weight='bold'
                className={styles.subTitle}>
                {i18n('Plug & play vehicle connectivity')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('With the diversity of use cases and needs for data, the need for hardware and software is present at all levels of the market, from OEMs to company fleets and mobility services. Traffilog can help to establish the desired link to connect to any vehicle via industry-leading hardware and software.')}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={8} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                // objectPosition='right center'
                className={styles.image}
                fluid={image1.fluid}
                alt={i18n('Plug & play vehicle connectivity')}/>
            </Grid>

          </Grid>

          <Grid container alignItems='flex-start' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={4} className={styles.textContainer}>
              <Img imgStyle={{ objectFit: 'none'}}
                objectFit='none'
                objectPosition='left center'
                className={styles.logo}
                fluid={logo2.fluid}
                alt={i18n('Direct hardware-free link to vehicles')}/>
              <Typography variant='h3'
                weight='bold'
                className={styles.subTitle}>
                {i18n('Direct hardware-free link to vehicles')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('Connecting to millions of vehicles through our partner network is already enabled for every single customer and partner eligible for it. We are taking care of all steps required for them to gain access to the vehicles, thus making it easier than ever to get connected. The data feeds directly into the platform.')}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={8} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                // objectPosition='right center'
                className={styles.image}
                fluid={image2.fluid}
                alt={i18n('Direct hardware-free link to vehicles')}/>
            </Grid>

          </Grid>

            
      </SectionWrapper>
  )}}/>
);