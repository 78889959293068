import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import sitemap from '../../data/sitemap';
import Hero from './Hero';
import Overview from './Overview';
import Connectivity from './Connectivity';
import Cloud from './Cloud';
import API from './API';
import Business from './Business';
import SaasTools from './SaasTools';
import CallToAction from '../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.platform}
    url='_url:platform'>
    <SEO {...seo} location={location}/>
    <Hero />
    <Overview />
    <Connectivity />
    <Cloud />
    <API />
    <Business />    
    <SaasTools/>
    <CallToAction/>
  </Layout>
)