import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import SingleTool from './SingleTool';
import styles from './styles.module.sass';

export default () => (
    <StaticQuery
      query={graphql`
        query {
          top: imageSharp(fluid: {originalName: {eq: "Platform-Saas-Tools-Top.png"}}) {
            fluid(maxWidth: 696) {
              ...GatsbyImageSharpFluid
            }
          }
          agreements: imageSharp(fluid: {originalName: {eq: "Platform-Saas-agreements.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          business: imageSharp(fluid: {originalName: {eq: "Platform-Saas-business.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          subscription: imageSharp(fluid: {originalName: {eq: "Platform-Saas-subscription.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          users: imageSharp(fluid: {originalName: {eq: "Platform-Saas-users.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          onboarding: imageSharp(fluid: {originalName: {eq: "Platform-Saas-onboarding.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          assistant: imageSharp(fluid: {originalName: {eq: "Platform-Saas-assistant.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          access: imageSharp(fluid: {originalName: {eq: "Platform-Saas-access.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
          support: imageSharp(fluid: {originalName: {eq: "Platform-Saas-support.jpg"}}) {
            fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
          }
        }
      `}
      render={({ top, agreements, business, subscription, users, onboarding, assistant, access, support }) => (
        <SectionWrapper className={styles.container} id={i18n('_hash:saas-tools')}>
          <Typography variant='h2'
            weight='bold'
            className={styles.titleContainer}>
            {i18n('SaaS tools')}
          </Typography>
          <Typography variant='subtitle2'
            weight='light'
            className={styles.subtitleContainer}>
            {i18n('In addition to the powerful platform, we are providing a set of SaaS tools to support key processes for implementing, managing, and improving the operations of our customers and partners.')}
          </Typography>
          <div className={styles.toolsTop}>
            <Img imgStyle={{ objectFit: 'none' }}
              objectFit='none'
              objectPosition='50% 0%'
              className={styles.toolsTopImage}
              fluid={top.fluid}/>
          </div>
          <Grid container justifyContent='center' alignContent='center' alignItems='center'
            className={styles.toolsContainer}>
            <SingleTool
                title={i18n('Digital agreements')}
                subtitle={i18n('Consent management engine adapting user agreements to the service and use case')}
                image={agreements}
            />
            <SingleTool
                title={i18n('Business intelligence')}
                subtitle={i18n('A customizable BI setup to help keep track of and improve operations')}
                image={business}
            />
            <SingleTool
                title={i18n('Subscription management')}
                subtitle={i18n('Adjustable subscriptions to adapt to the selected model and ease billing')}
                image={subscription}
            />
            <SingleTool
                title={i18n('User management')}
                subtitle={i18n('Set user management on auto-pilot and interfere whenever required')}
                image={users}
            />
            <SingleTool
                title={i18n('In-product onboarding')}
                subtitle={i18n(' Secure a great start for customers by utilizing in-product onboarding flows and help center articles')}
                image={onboarding}
            />
            <SingleTool
                title={i18n('Smart assistant')}
                subtitle={i18n('Automation of administrative and reporting tasks to ease operations')}
                image={assistant}
            />
            <SingleTool
                title={i18n('Access management')}
                subtitle={i18n('Flexibility in administrating access grants to adapt to any structure.')}
                image={access}
            />
            <SingleTool
                title={i18n('Support overview')}
                subtitle={i18n('Having control of support operations when actions are necessary')}
                image={support}
            />
          </Grid>
        </SectionWrapper>
      )}/>
  );