import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: { originalName: {eq: "platform_06_business.png"} }) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image }) => {
      return (<SectionWrapper className={styles.container}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.textContainer}>
              <Typography variant='h2'
                weight='bold'
                className={styles.title}>
                {i18n('Explore the digital world powered by Traffilog but adapted to your business')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('We offer a variety of web, mobile, or in-vehicle user interfaces off-the-shelf as well as API documentation that will enable you to utilise data in your own applications.')}
              </Typography>
              <div className={styles.listItem}>
                {i18n('Web, mobile, or in-vehicle applications')}
              </div>

              <div className={styles.listItem}>
                {i18n('White-label customization')}
              </div>

              <div className={styles.listItem}>
                {i18n('Fast and simple localization')}
              </div>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                // objectPosition='right center'
                className={styles.image}
                fluid={image.fluid}
                alt={i18n('Explore the digital world powered by Traffilog but adapted to your business')}/>
            </Grid>

          </Grid>

      </SectionWrapper>
  )}}/>
);