import React from "react";
import Content from '../views/Platform';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Traffilog Connected Vehicle Platform | Connect, Monitor and Control Your Fleet with Ultimate Flexibility",
    lang: 'en',
    description: "Traffilog's Connected Vehicle Platform offers advanced technology to connect, monitor and control your fleet. Enhance fleet management with real-time tracking and automation. Get started today!",
    meta: [{
      name: 'keywords',
      content: 'connected vehicle platform, automotive connectivity,  platform, cloud services',
    }]
  }}/>
);