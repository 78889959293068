import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: {
          originalName: {eq: "platform_02_overview.jpg"}
        }) {
          fluid(maxHeight: 675, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image }) => {
      return (<SectionWrapper className={styles.container}>
        <div className={styles.overviewInner}>
          <Typography variant='h2'
            weight='bold'
            className={styles.titleContainer}>
            {i18n('Traffilog Platform overview')}
          </Typography>
          <Typography variant='subtitle2'
            weight='light'
            className={styles.subtitleContainer}>
            {i18n('It’s up to the requirements of the particular project to discuss whether to use all or part of the platform modules.')}
          </Typography>
            <Img imgStyle={{ objectFit: 'contain'}}
              objectFit='contain'
              className={styles.image}
              fluid={image.fluid}
              alt={i18n('Traffilog Platform overview')}/>
        </div>
      </SectionWrapper>
  )}}/>
);