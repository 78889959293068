import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({ image, text }) => (
    <div className={styles.featureItem}>          
        <Img imgStyle={{ objectFit: 'none'}}
            objectFit='none'
            objectPosition='50% 50%'
            className={styles.featureImage}
            fluid={image.fluid}
            alt={text}/>
        <Typography variant='subtitle2'
        weight='light'
        className={styles.featureText}>
        {text}
        </Typography>
    </div>
);